<i18n>
{
    "ru": {
        "news_one": "новость",
        "news_two": "новости",
        "news_three": "новостей",
        "article_one": "статья",
        "article_two": "статьи",
        "article_three": "статей"
    }
}
</i18n>
<template>
    <ul class="rubrics-list"
        :class="rootClasses">

        <li class="rubrics-list__item"
            v-for="rubric in items"
            :key="rubric.name">

            <html-link
                v-if="rubric.htmlUrl"
                @click.native="scrollToHead"
                class="rubrics-list__link"
                :url="rubric.htmlUrl"
                :aria-label="rubric.name"/>

            <picture class="rubrics-list__picture">
                <img
                    v-lazy-load :data-src="get(rubric, 'picture.url', getNoImage)"
                    :alt="rubric.name"
                    :class="!get(rubric, 'picture.url') ? 'rubrics-list__picture_no-image' : ''"/>
            </picture>

            <div class="rubrics-list__content">
                <div class="rubrics-list__name">{{ rubric.name }}</div>
                <div class="rubrics-list__caption">{{ getArticleDeclension(rubric) }}</div>
                <svg-icon class="rubrics-list__icon" name="chevron-right-gray"/>
            </div>
        </li>
    </ul>
</template>

<script>
import includes from 'lodash/includes'
import get from 'lodash/get'

import { mapGetters } from 'vuex'

export default {
    name: 'RubricsList',
    props: {
        items: {
            type: Array,
            default: () => []
        },
        type: {
            type: String,
            default: 'big',
            validator(value) {
                return includes(['big', 'small'], value)
            }
        }
    },
    computed: {
        ...mapGetters(['getNoImage']),
        rootClasses() {
            return {
                'rubrics-list_small': this.type === 'small'
            }
        }
    },
    methods: {
        get,
        scrollToHead() {
            this.$scrollTo('html', 750)
        },
        getArticleDeclension(section) {
            if (section.articles && section.articles.total) {
                const count = get(section, 'articles.total')

                if (!count) return

                const notices = [
                    this.$t('article_one'),
                    this.$t('article_two'),
                    this.$t('article_three')
                ]

                return this.$options.filters.numberDeclension(count, notices)
            }

            if (section.news && section.news.total) {
                const count = get(section, 'news.total')

                if (!count) return

                const notices = [
                    this.$t('news_one'),
                    this.$t('news_two'),
                    this.$t('news_three')
                ]

                return this.$options.filters.numberDeclension(count, notices)
            }
        },
    }
}
</script>

<style lang="less">
.rubrics-list {
    list-style: none;

    margin: 0;
    padding: 0;

    @{desktop} & {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        padding-top: rem(10);
    }

    &__item {
        display: flex;
        align-items: center;

        border-bottom: rem(1) solid @color-gray-white;

        padding-top: rem(18);
        padding-bottom: rem(15);

        position: relative;

        .page-padding();

        @{mobile} &:last-child {
            border: none;
        }

        @{desktop} & {
            padding-top: rem(11);
            padding-bottom: rem(11);

            width: calc(50% - rem(76/2));
        }
    }

    &__item:hover &__name {

        @media @hover {
            color: @color-primary;
        }
    }

    &__picture {
        display: block;

        border-radius: 50%;

        height: rem(56);
        width: rem(56);

        overflow: hidden;

        img {
            display: block;
            object-fit: cover;
            border-radius: 50%;

            height: 100%;
            width: 100%;
        }

        &_no-image {
            margin-top: rem(2.5);
        }

        @{desktop} & {
            height: rem(40);
            width: rem(40);
        }
    }

    &__content {
        flex: 1;

        padding-left: rem(16);

        position: relative;

        @{desktop} & {
            padding-top: rem(2);
            display: flex;
            justify-content: space-between;
        }
    }

    &__name {
        font-size: rem(15);
        font-weight: 500;
    }

    &__caption {
        color: @color-gray-light;
        font-size: rem(13);

        @{desktop} & {
            padding-right:rem(27);
            margin-top: rem(1);
        }
    }

    &__icon {
        margin-left: rem(5);

        height: rem(18);
        width: rem(18);

        position: absolute;
        top: calc(50%);
        right: rem(-5);

        transform: translateY(-50%);

        @{desktop} & {
            height: rem(16);
            width: rem(16);

            right: rem(1);
        }
    }

    &__link {
        display: block;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    &_small &__icon {
        display: none;
    }

    &_small &__item {
        border: none;

        padding-top: rem(10);
        padding-right: 0;
        padding-bottom: rem(10);
        padding-left: 0;
    }

    &_small &__picture {
        height: rem(32);
        width: rem(32);
    }
}
</style>
