import get from 'lodash/get'
import UserAccount from "@/models/users/accounts/index";
import Like from '@/models/services/like/'

export default {
    methods: {
        async getUserLikes(items,push=false){
            if (this.$auth.loggedIn && items.length){
                if (this.$auth.user.id){

                    const userAccount = new UserAccount
                    const likesModel = new Like().for(userAccount)

                    const itemCodes = []

                    items.forEach((item) => {
                        if(get(item, 'code', false)){
                            itemCodes.push(item.code)
                        }
                    })

                    if(itemCodes.length){
                        const likes = await likesModel.params({
                            entity_type: 'post,article,news',
                            per_page: 100,
                            entity_code: itemCodes.join(',')
                        }).get()

                        const itemsRatings = get(likes, '[0].items', [])
                        
                        const storeEvent = push ? 'pushUserLikesRatings' : 'setUserLikesRatings'

                        this.$store.commit(storeEvent, itemsRatings)
                        this.$bus.$emit('fetch-complete')
                    }
                }
            }
        },
        async getUserCommentsLikes(items,type,push=false){
            if (this.$auth.loggedIn && items.length){
                if (this.$auth.user.id){

                    const itemCodes = []

                    items.forEach((item) => {
                        if(get(item, 'id', false)){
                            itemCodes.push(item.id)
                        }
                        if(item.replies){
                            item.replies.forEach((reply) => {
                                itemCodes.push(reply.id)
                            })
                        }
                    })

                    if(itemCodes.length){
                        const userAccount = new UserAccount
                        const likes = await userAccount.commentsRatings().params({
                            per_page: 100,
                            comment: itemCodes.join(',')
                        }).get()

                        const itemsRatings = get(likes, '[0].items', [])

                        const storeEvent = push ? 'pushCommentsRatings' : 'setCommentsRatings'

                        this.$store.commit(storeEvent, itemsRatings)
                        this.$bus.$emit('fetch-complete', itemsRatings)
                    }
                }
            }
        },
        async getFeedLikes(items,type,push=false){

            const userAccount = new UserAccount
            const likesModel = new Like().for(userAccount)

            const entityCodes = []
            const entityIds = []

            items.forEach((item) => {
                if(get(item, 'entity.id', false)){
                    entityIds.push(item.entity.id)
                }else if(get(item, 'entity.code', false)){
                    entityCodes.push(item.entity.code)
                }
            })

            const likes = await likesModel.params({
                entity_type: 'post,article,news',
                per_page: 100,
                entity_code: entityCodes.join(',')
            }).get()

            const comments = await userAccount.commentsRatings().params({
                per_page: 100,
                comment: entityIds.join(',')
            }).get()

            const itemsLikes = get(likes, '[0].items', [])
            const itemsComments = get(comments, '[0].items', [])

            const storeEvent = push ? 'pushUserLikesRatings' : 'setUserLikesRatings'
            const storeCommentsEvent = push ? 'pushCommentsRatings' : 'pushCommentsRatings'

            this.$store.commit(storeEvent, itemsLikes);
            this.$store.commit(storeCommentsEvent, itemsComments);

            this.$bus.$emit('fetch-complete', itemsComments);
        }
    }
}