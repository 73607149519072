<template>
    <section
        :class="postsListClasses"
        class="posts-grid">

        <!-- Первые 4 постов + рекоммендованные + подписка -->
        <div
            class="posts-grid__articles grid-container grid-container_free"
            v-if="isFree">

            <BlockArticle
                class="posts-grid__article"
                v-for="article in posts"
                :key="article.code"
                v-bind="getArticleProperties(article)" />

        </div>

        <!-- Первые 5 постов + рекоммендованные + подписка -->
        <div
            class="posts-grid__articles grid-container grid-container_first"
            v-if="getDataCount && !isFree"
            :data-count="getDataCount">

            <div class="grid-container__header">
                <BlockArticle
                    class="posts-grid__article"
                    v-for="article in headPosts"
                    :key="article.code"
                    v-bind="getArticleProperties(article)" />
            </div>

            <div class="grid-container__footer"
                v-if="secondPosts.length">

                <BlockArticle
                    class="posts-grid__article"
                    v-for="article in secondPosts"
                    :key="article.code"
                    size="long"
                    v-bind="getArticleProperties(article)" />
            </div>

            <div
                class="grid-container__wide"
                v-if="isShowSlots">

                <slot name="wide"></slot>
            </div>

            <div
                class="grid-container__small"
                v-if="isShowSlots">

                <slot v-if="isShowSubscribe" name="subscribe"></slot>

                <BlockArticle
                    v-else-if="subscribePost && subscribePost.code"
                    class="posts-grid__article"
                    v-bind="getArticleProperties(subscribePost)" />
            </div>
        </div>

        <!-- Остальные посты по 7 штук -->
        <div
            class="posts-grid__articles grid-container"
            v-for="(group, index) in otherPosts"
            :key="group[0].code + index"
            :data-count="group.length">

            <div class="grid-container__header">
                <BlockArticle
                    class="posts-grid__article"
                    v-for="article in take(group, 3)"
                    :key="article.code"
                    v-bind="getArticleProperties(article)" />
            </div>

            <div
                class="grid-container__wide"
                v-if="group[3]">

                <BlockArticle
                    class="posts-grid__article"
                    size="wide"
                    v-bind="getArticleProperties(group[3])" />
            </div>

            <div class="grid-container__small"
                v-if="group[4]">

                <BlockArticle
                    class="posts-grid__article"
                    v-bind="getArticleProperties(group[4])" />
            </div>

            <div
                class="grid-container__footer"
                v-if="group[5]">

                <BlockArticle
                    class="posts-grid__article"
                    v-for="article in drop(group, 5)"
                    :key="article.code"
                    size="long"
                    v-bind="getArticleProperties(article)" />
            </div>

        </div>
        <BasePagination
            v-if="!noPagination"
            class="posts-grid__pagination"
            @input="pageChange"
            v-model="page"
            :page-range="7"
            :page-count="lastPage"/>
    </section>
</template>

<script>
import get from 'lodash/get'
import head from 'lodash/head'
import chunk from 'lodash/chunk'
import take from 'lodash/take'
import drop from 'lodash/drop'
import debounce from 'lodash/debounce'
import url from '@/filters/url'

import BlockArticle from '@/components/layout/blocks/BlockArticle'

import UserLikes from '@/mixins/userLikes.mixin'

export default {
    name: 'PostsGrid',
    components: {
        BlockArticle
    },
    mixins: [ UserLikes ],
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        list: {
            type: Object,
            default: () => {},
            // required: true
        },
        onlyLayout: {
            type: Boolean,
            default: false
        },
        hideSlots: {
            type: Boolean,
            default: false
        },
        isFree: {
            type: Boolean,
            default: false
        },
        singlePost: {
            type: Object,
            default: () => {}
        }
    },
    // inject: [
    //     'singlePost'
    // ],
    data() {
        return {
            page: 1,
            isShowSlots: false,
            isShowSubscribe: true,
            headPosts: [],
            secondPosts: [],
            otherPosts: [],
            subscribePost: {},
            loading: false,
            posts: [],
            lastPage: 1,
            currentPage: 1,
            currentPageUrl: null,
            nextPageUrl: null,
            prevPageUrl: null,
            baseUrl: null,
            perPage: null,
        }
    },
    computed: {
        getDataCount() {
            if (this.onlyLayout) return 0
            if (this.isShowSlots) return 7
            if (this.posts.length > 7) return 7
            if (this.posts.length) return this.posts.length

            return 0
        },
        getUrl() {
            return url.modifyApiQuery(this.baseUrl, {page: this.page});
        },
        noPagination() {
            return this.lastPage <= 1
        },
        postsListClasses() {
            return {
                'posts-grid_no-pagination': this.noPagination
            }
        }
    },
    watch: {
        // page() {
        //     console.log('watch page');
        //     this.load()
        // },
        posts() {
            this.init()
        },
        isShowSubscribe(value) {
            if (value) return
            if (!this.isShowSlots) return
            this.init()
            // this.getSinglePost();
        },
        singlePost(value){
            this.init();
            this.getUserLikes([value]);
        }
    },
    globalBus: {
        'page-change'(page){
            this.page = page || 1;
        },
        'fetch-complete'() {
            if (!this.isShowSlots) return
            this.isShowSubscribe = !get(this.$auth, 'user.id', false)
        }
    },
    created() {
        this.page = get(this.list, 'currentPage', 1)

        if (this.list) {
            this.read(this.list, true);
            this.isShowSlots = this.hideSlots ? false : this.currentPage === 1
        } else {
            this.posts = this.items
            this.isShowSlots = !this.hideSlots
        }

        this.init()
    },
    beforeMount(){
        this.getUserLikes(this.items)
    },
    methods: {
        get,
        head,
        take,
        drop,
        init() {
            let posts = this.posts

            if (this.onlyLayout) {
                this.otherPosts = chunk(this.posts, 7)
                return
            }

            // Первые три поста
            this.headPosts = take(posts, 3)
            posts = drop(posts, this.headPosts.length)

            // Вторые два поста
            this.secondPosts = take(posts, 2)
            posts = drop(posts, this.secondPosts.length)

            // Если пользователь авторизован,
            // и если он на первой странице,
            // то забираем одну плитку со следующей страницы
            if (!this.isShowSubscribe && this.isShowSlots) {
                this.subscribePost = head(posts)
                if (this.singlePost && this.singlePost.code) {
                    posts.push(this.singlePost)
                }
                posts = drop(posts)
            } else {
                this.subscribePost = {}
            }

            // Остальные посты по 7
            this.otherPosts = chunk(posts, 7)
        },
        getArticleProperties(article) {
            if (!article) return

            return {
                date: article.createdAt,
                source: article.source,
                entityType: article.entityType,
                code: article.code,
                name: this.getArticleName(article),
                picture: get(article, 'picture.url'),
                alt: get(article, 'picture.alt'),
                title: article.name,
                url: article.htmlUrl,
                userUrl: get(article, 'createdBy.htmlUrl', get(article, 'createdBy[0].htmlUrl', '')),
                section: article.section,
                description: article.anons,
                likes: get(article, 'likes.total'),
                comments: get(article, 'comments.total'),
                views: get(article, 'views.total'),
                pictureThumbs: get(article, 'pictureThumbs'),
                coverThumbs: get(article, 'coverThumbs')
            }
        },
        getArticleName(article) {
            return get(article, 'createdBy.fullName') ||
                get(article, 'createdBy[0].fullName', null)
        },
        read(list, initial) {
            if (!list) return

            this.posts = list.items

            this.lastPage = list.lastPage;
            this.currentPage = list.currentPage;
            this.currentPageUrl = list.currentPageUrl;
            this.nextPageUrl = list.nextPageUrl;
            this.prevPageUrl = list.prevPageUrl;

            if (!this.baseUrl)
                this.baseUrl = list.currentPageUrl;

            if (!this.perPage)
                this.perPage = list.perPage;

            this.isShowSlots = this.hideSlots ? false : this.currentPage === 1

            this.isShowSubscribe = !get(this.$auth, 'user.id', false) && this.isShowSlots;

            if(!initial)
                !this.$url || this.$url.modify({page: this.currentPage}, this.$route.name);
        },
        /**
         * Запрашивает данные
         *
         * @param  {Boolean} isQuery
         */
        load: debounce(function(isQuery) {
            this.loading = true;
            this.$api.get(this.getUrl)
                .then((response) => {
                    this.read(response.data);
                }).finally(() => {
                    this.$bus.$emit('page-change', this.page)
                    this.loading = false;

                    this.$scrollTo('.posts-grid', 1250, {
                        offset: -130,
                    })
                });
        }, 1000),
        pageChange(page){
            this.page = page || 1;
            this.load();
        }
    }
}
</script>

<style lang="less">
.posts-grid {
    padding-bottom: rem(11);

    &__article {
        border-bottom: rem(1) solid @color-gray-white;

        @{desktop} & {
            height: 100%;
        }
    }

    &__pagination {
        margin-top: rem(11);
    }

    &_no-pagination {
        padding-bottom: 0;

        // .posts-grid__article {
        //     &:last-child {
        //         // border-bottom: none;
        //     }
        // }
    }

    .grid-container {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: min-content !important;
        gap: rem(64) rem(28);

        padding-bottom: rem(64);

        &[data-count="1"],
        &[data-count="2"],
        &[data-count="3"] {
            grid-template-areas:
                "header header header";
        }

        &[data-count="4"],
        &[data-count="5"] {
            grid-template-areas:
                "header header header"
                "wide wide small"
        }

        &[data-count="6"],
        &[data-count="7"] {
            grid-template-areas:
                "header header header"
                "wide wide small"
                "footer footer footer";
        }

        &_first[data-count="7"] {
            grid-template-areas:
                "header header header"
                "footer footer footer"
                "wide wide small"
        }

        &_free {
            grid-template-areas: none;
            grid-template-rows: none;
            grid-template-columns: repeat(2, 1fr);
        }

    }

    .grid-container__wide {
        grid-area: wide;
    }

    .grid-container__footer {
        display: grid;
        grid-area: footer;
        grid-template-areas: ". .";
        grid-template-rows: 1fr;
        grid-template-columns: repeat(2, 1fr);
        gap: 0 rem(28);
    }

    .grid-container__header {
        display: grid;
        grid-area: header;
        grid-template-areas: ". . .";
        grid-template-rows: 1fr;
        grid-template-columns: repeat(3, 1fr);
        gap: 0 rem(28);
    }

    .grid-container__small {
        grid-area: small;
        
        @{desktop} & {
            max-width: rem(338);
        }
    }
}
</style>
