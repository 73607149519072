<template>
    <swiper
        class="page-gallery"
        :class="classes"
        ref="swiper"
        :auto-destroy="false"
        :delete-instance-on-destroy="false"
        :cleanup-styles-on-destroy="false"
        @ready="onSwiperInit"
        @slide-change="onSlideChange"
        :options="swiperOptions">

        <swiper-slide
            v-for="(post, index) in items"
            :data-index="index"
            :key="post.code">

            <html-link class="page-gallery__link"
                v-if="post.htmlUrl"
                :url="post.htmlUrl"
                aria-label="gallery"/>

            <picture class="page-gallery__picture">
                <img
                    data-not-lazy
                    v-lazy-load :data-src="getImage(post)"
                    :alt="post.name || ''" />
            </picture>

            <div class="page-gallery__content">
                <!-- eslint-disable-next-line -->
                <h6 class="page-gallery__title" v-html="post.name"></h6>
                <div class="page-gallery__name">{{ getPostUsername(post) }}</div>
            </div>
        </swiper-slide>

        <button
            v-if="showNavigation"
            slot="button-prev"
            class="swiper-button-prev page-gallery__button page-gallery__button_prev">

            <svg-icon name="chevron-left-current" />

        </button>

        <button
            v-if="showNavigation"
            slot="button-next"
            class="swiper-button-next page-gallery__button page-gallery__button_next">

            <svg-icon name="chevron-right-current" />

        </button>

        <div class="page-gallery__dots swiper__dots"
            v-if="showDots"
            slot="pagination"></div>

    </swiper>
</template>

<script>
import get from 'lodash/get'

import GalleryMixin from '@/mixins/gallery.mixin'

export default {
    name: 'PageGallery',
    mixins: [
        GalleryMixin
    ],
    props: {
        transparentDots: {
            type: Boolean,
            default: false
        },
        pictureProperty: {
            type: String,
            default: 'picture'
        },
        items: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
        }
    },
    computed: {
        classes() {
            return {
                'page-gallery_shown': this.isShowSlider,
                'page-gallery_transparent-dots': this.transparentDots,
            }
        },
        isShowButtons() {
            return this.items && this.items.length > 1
        },
    },
    methods: {
        get,
        getImage(post) {
            const prop = get(post, this.pictureProperty) ?
                this.pictureProperty :
                'picture'

            return get(post, `${prop}.url`)
        },
        getPostUsername(post) {
            return get(post, 'createdBy.fullName') ||
                get(post, 'createdBy[0].fullName', null)
        },
    }
}
</script>

<style lang="less">
.page-gallery {
    position: relative;
    z-index: 0;

    opacity: 0;

    transition: opacity .15s ease-in;

    &__link {
        display: block;

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    .swiper-wrapper:not([style]) {
        transition: transform .3s ease-out;
    }

    .swiper-slide {
        position: relative;
    }

    &__content {
        color: @color-white;

        padding-bottom: rem(19);

        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;

        .page-padding();

        @{desktop} & {
            display: flex;
            flex-direction: column;

            text-align: center;

            margin: 0 auto;
            padding-bottom: rem(44);

            max-width: 50%;
        }
    }

    &__title {
        font-size: rem(20);
        line-height: 130%;

        margin-bottom: rem(6);

        @{desktop} & {
            font-size: rem(24);
        }
    }

    &__name {
        font-size: rem(14);
        line-height: 150%;

        @{desktop} & {
            order: -1;

            font-size: rem(15);

            margin-bottom: rem(8);
        }
    }

    &__picture {
        display: block;

        text-align: center;

        height: 100%;
        width: 100%;

        position: relative;

        transition: transform .2s ease-in;

        @{mobile} & {
            transform: scale(.9);
        }

        img {
            object-fit: cover;

            height: calc(100vw * 250 / 375);
            width: 100%;
            max-height: 100%;

            @{desktop} & {
                height: rem(485);
            }
        }

        &:after {
            content: '';
            display: block;

            background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, black 100%);

            height: rem(148);

            position: absolute;
            right: 0%;
            bottom: 0;
            left: 0%;

            opacity: 0.7;

            @{desktop} & {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, black 100%);

                height: rem(205);

                opacity: 1;
            }
        }
    }

    &__dots {
        padding-top: rem(12);

        min-height: rem(34);

        transform: translateY(rem(-7));
    }

    &_transparent-dots &__dots {
        white-space: nowrap;

        position: absolute;
        bottom: rem(5);
        left: 50%;
        z-index: 1;

        transform: translateX(-50%);

        @{desktop} & {
            bottom: rem(18);
        }

        .swiper-pagination-bullet {
            background-color: @color-white !important;
            outline: none;

            opacity: .5;

            transition: opacity .3s ease;

            .hover({
                opacity: .7;
            });

            &-active {
                opacity: 1;
            }
        }
    }

    &_shown {
        opacity: 1;
    }

    @{mobile} &_shown &__picture {
        transform: scale(1);
    }

    @{desktop} &__button {
        display: flex;
        align-items: center;
        justify-content: center;

        background: transparent;
        border: none;

        margin-top: 0;
        padding: 0;

        height: 100%;
        width: rem(64);

        top: 0;
        z-index: 2;

        transition: background-color .2s ease;

        svg {
            color: @color-white;

            height: rem(24);
            width: rem(24);
        }

        &_prev {
            left: 0;
        }

        &_next {
            right: 0;
        }

        &[aria-disabled=true] {
            pointer-events: auto;
        }

        &:after,
        &:before {
            display: none;
        }

        &:hover {
            background-color: rgba(0, 0, 0, .4);
        }
    }
}
</style>
