import get from 'lodash/get'
import { mapGetters } from 'vuex'

export default {
    model: {
        // prop: 'selected-slide',
        event: 'slide-change'
    },
    props: {
        delay: {
            type: Number,
            default: 5000
        },
        autoplay: {
            type: Boolean,
            default: false
        },
        pictures: {
            type: Array,
            default: () => []
        },
        loop: {
            type: Boolean,
            default: false
        },
        fade: {
            type: Boolean,
            default: false
        },
        showNavigation: {
            type: Boolean,
            default: false
        },
        showDots: {
            type: Boolean,
            default: false
        },
        followFinger: {
            type: Boolean,
            default: true
        },
        // selectedSlide: {
        //     type: Number,
        //     default: 0
        // },
        slideIndex: {
            type: Number,
            default: 0
        },
        cleanUp: {
            type: Boolean,
            default: true
        },
        keyboardEnabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isShowSlider: false,
        }
    },
    computed: {
        $swiper() {
            return this.$refs.swiper.$swiper
        },
        swiperOptions() {
            const options = {
                slidesPerView: 1
            }

            if (!this.followFinger) {
                options.followFinger = false
            }

            if (this.loop) {
                options.loop = true
            }

            if (this.showNavigation) {
                options.navigation = {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }
            }

            if (this.fade) {
                options.effect = 'fade'
                options.fadeEffect = {
                    crossFade: true
                }
            }

            if (this.showDots) {
                options.pagination = {
                    el: '.swiper__dots',
                    clickable: true
                }
            }

            if (this.autoplay) {
                options.autoplay = {
                    delay: this.delay,
                    disableOnInteraction: false,
                }
            }

            if( this.slideIndex ) {
                options.initialSlide = this.slideIndex
            }

            return options
        },
    },
    // watch: {
    //     selectedSlide(value) {
    //         this.slideTo(value)
    //         this.$bus.$emit('perfume-slide-change', value)
    //     }
    // },
    created(){
        if(this.keyboardEnabled)
            document.addEventListener('keyup', this.arrowListener);
    },
    beforeDestroy(){
        if(this.keyboardEnabled)
            document.removeEventListener('keyup', this.arrowListener);
    },
    mounted() {
        this.$nextTick(() => {
            // if (this.selectedSlide) {
            //     this.slideTo(this.selectedSlide)
            // }

            this.lazyLoadImage(this.$refs.swiper.$el)
            this.$refs.swiper.updateSwiper()

            if(this.loop){
                // грузим картинки клонированных слайдов
                const media = this.$refs.swiper.$el.querySelectorAll('[data-src]');
                [...media].forEach(m => this.$lazyLoad(m))  
            }
        })
    },
    methods: {
        ...mapGetters(['getNoImage']),
        lazyLoadImage(e) {
            const media = e.querySelectorAll('[v-lazy-load]');
            [...media].forEach(m => this.$lazyLoad(m))
        },
        getVideoCover(video) {
            if(get(video, 'cover', false)){
                return get(video, 'cover', {
                    url: this.getNoImage(),
                    alt: ''
                })
            }else{
                return {
                    url: `https://img.youtube.com/vi/${this.getYoutubeVideoId(video.htmlUrl)}/default.jpg`,
                    alt: ''
                }
            }
        },
        slideTo(index) {
            this.$refs.swiper.$swiper.slideTo(index)
        },
        onSwiperInit() {
            this.isShowSlider = true
        },
        fadeOut() {
            this.isShowSlider = false
        },
        onSlideChange() {
            const activeIndex = this.$refs.swiper.$swiper.activeIndex
            const activeSlide = this.$refs.swiper.$swiper.slides[activeIndex]
            let currentIndex = 0

            if (activeSlide) {
                currentIndex = activeSlide.dataset.index
            }

            this.$emit('slide-change', parseInt(currentIndex))
        },
        getYoutubeVideoId(url) {
            // eslint-disable-next-line
            const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
            const match = url.match(regExp);
            return (match && match[7].length === 11) ? match[7] : false;
        },
        arrowListener(e){
            // left
            if(e.keyCode === 37){
                this.$swiper.slidePrev();
            }
            // right
            if(e.keyCode === 39){
                this.$swiper.slideNext();
            }
        }
    }
}
